<template>
	<apply-detail :is-refund="true" />
</template>

<script>
import ApplyDetail from './components/applyInfo'

export default {
	name: 'RefundForm',  // 退款
	components: {
		ApplyDetail
	}
}
</script>
